import type { MetaFunction } from "@remix-run/node";

type MetaData = ReturnType<MetaFunction>[number];

type MetaContent = (meta?: MetaData[]) => MetaData[];

export const getMetaContent: MetaContent = (metaList) => {
  const titleMeta = metaList?.find((meta) => "title" in meta && !!meta.title);
  const descriptionMeta = metaList?.find(
    (meta) => "name" in meta && meta.name === "description"
  );

  return [
    ...(titleMeta
      ? []
      : [
          {
            title: "Timable 香港 - 合時行樂",
          },
        ]),
    ...(descriptionMeta
      ? []
      : [
          {
            name: "description",
            content: "",
          },
        ]),
    ...(metaList || []),
  ];
};
